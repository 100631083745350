import { render, staticRenderFns } from "./shopHeader.vue?vue&type=template&id=ae8dc79c&scoped=true&"
import script from "./shopHeader.vue?vue&type=script&lang=js&"
export * from "./shopHeader.vue?vue&type=script&lang=js&"
import style0 from "./shopHeader.vue?vue&type=style&index=0&id=ae8dc79c&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ae8dc79c",
  null
  
)

export default component.exports